<template>
  <div>
    <PageHeader />
    <b-overlay :show="isFetching">
      <b-card class="mb-4">
        <ValidationObserver
          ref="botSettings"
          v-slot="{ handleSubmit }"
        >
          <form @submit.prevent="handleSubmit(onSubmit)">
            <b-form-group
              :label="$t('fields.status')"
              label-for="status"
            >
              <b-form-radio-group
                id="status"
                v-model="checkInRewardsForm.status"
                name="status"
              >
                <b-form-radio value="enable">
                  {{$t('utils.status.enable')}}
                </b-form-radio>
                <b-form-radio value="disable">
                  {{$t('utils.status.disable')}}
                </b-form-radio>
              </b-form-radio-group>
            </b-form-group>
            <b-form-group
              label="วันที่เริ่ม - สิ้นสุด"
              label-for="start_date"
            >
              <b-row>
                <b-col>
                  <b-input-group prepend="เริ่ม">
                    <b-form-input
                      id="start_date"
                      v-model="checkInRewardsForm.start_date"
                      v-mask="'##-##-####'"
                      placeholder="DD-MM-YYYY (01/01/2021)"
                    ></b-form-input>
                  </b-input-group>
                </b-col>
                <b-col>
                  <b-input-group prepend="สิ้นสุด">
                    <b-form-input
                      id="end_date"
                      v-model="checkInRewardsForm.end_date"
                      v-mask="'##-##-####'"
                      placeholder="DD-MM-YYYY (01/01/2021)"
                    ></b-form-input>
                  </b-input-group>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group
              label="หัวข้อ"
              label-for="title"
            >
              <b-input
                id="title"
                v-model="checkInRewardsForm.title"
              >
              </b-input>
            </b-form-group>
            <b-form-group :label="$t('fields.description')">
              <vue-editor v-model="checkInRewardsForm.description"></vue-editor>
            </b-form-group>
            <b-form-group
              :label="$t('fields.image')"
              label-for="img"
            >
              <b-form-file
                id="img"
                v-model="file"
                accept="image/*"
              >
              </b-form-file>
              <b-form-text>
                <b-link
                  v-if="file"
                  class="text-danger"
                  @click="file = ''"
                >
                  {{ $t('buttons.remove')}}
                </b-link>
              </b-form-text>
            </b-form-group>
            <b-row
              v-for="(row, index) in checkInRewardsForm.ranges"
              :key="index"
            >
              <b-col cols="3">
                <b-form-group label="ลำดับวันเช็คอิน">
                  <b-form-input v-model="row.date"></b-form-input>

                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="จำนวนเงินรางวัล">
                  <b-form-input v-model="row.cashback_value"></b-form-input>

                </b-form-group>
              </b-col>
              <b-col
                cols="1"
                class="p-0"
              >
                <b-form-group :label="$t('buttons.remove')">
                  <b-button
                    variant="danger"
                    @click="onRemove(index)"
                  >
                    <i class="uil uil-trash"></i>
                  </b-button>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-button
                  size="sm"
                  @click="onAdd"
                >
                  <i class="uil uil-plus"></i>
                  {{ $t('buttons.add')}}
                </b-button>
              </b-col>
            </b-row>

            <div class="text-right">
              <b-overlay
                :show="isLoading"
                rounded
                spinner-small
                class="d-inline-block"
              >
                <b-button
                  type="submit"
                  variant="primary"
                  block
                >
                  {{ $t('buttons.edit')}}
                </b-button>
              </b-overlay>
            </div>
          </form>
        </ValidationObserver>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { VueEditor } from 'vue2-editor'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  page: {
    title: 'Check-in Rewards',
  },
  components: { VueEditor },
  data() {
    return {
      file: null,
      typeOptions: [
        { text: this.$t('fields.amount'), value: 'AMOUNT' },
        { text: this.$t('fields.percentage'), value: 'PERCENT' },
      ],
      checkInRewardsForm: {
        start_date: '',
        end_date: '',
        status: 'enable',
        title: '',
        description: '',
        img: '',
        ranges: [
          { date: 1, cashback_value: 0 },
          { date: 2, cashback_value: 0 },
          { date: 3, cashback_value: 0 },
          { date: 4, cashback_value: 0 },
        ],
      },
    }
  },
  computed: {
    ...mapState({ }),
    ...mapGetters(['checkInRewardsSettings']),
    isFetching() { return false },
    isLoading() { return false },
  },
  watch: {
    checkInRewardsSettings(val) {
      if (val) {
        this.setData()
      }
    },
  },
  created() {
  },
  methods: {
    ...mapActions([ ]),
    setData() {
      if (this.checkInRewardsSettings) {
        const data = JSON.parse(JSON.stringify(this.checkInRewardsSettings))
        this.checkInRewardsForm = { ...this.checkInRewardsForm, ...data }
      } else {
        this.checkInRewardsForm = {
          start_date: '',
          end_date: '',
          status: 'enable',
          title: '',
          description: '',
          img: '',
          ranges: [
            { date: 1, cashback_value: 0 },
            { date: 2, cashback_value: 0 },
            { date: 3, cashback_value: 0 },
            { date: 4, cashback_value: 0 },
          ],
        }
      }
    },
    onAdd() {
      this.checkInRewardsForm.ranges.push({
        date: this.checkInRewardsForm.ranges.length + 1,
        cashback_value: 0,
      })
    },
    onRemove(index) {
      this.checkInRewardsForm.ranges.splice(index, 1)
    },
    onSubmit() {
      this.checkInRewardsForm.img = this.file
      //   this.checkInSettings(this.checkInRewardsForm)
    },
  },
}
</script>
